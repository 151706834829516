import React, { useState } from 'react';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getStorage, listAll } from "firebase/storage";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Container  from 'react-bootstrap/Container';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {  Button } from 'react-bootstrap';
import { storage } from '../../Firebase';
import {db} from '../../Firebase'


import { collection, addDoc } from "firebase/firestore";
const ImageUpload = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [url, setUrl] = useState([]);
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
   
    if (e.target.files[0]) {
      setImage(e.target.files[0]);



    }

    
  };

  const saveTodatabase = () => {

   addDoc(collection(db, "Gallery"), {
        title: title,
        desc: desc,
        image: url
      }) .then(() => {
        alert('Video/Image Uploaded successfully!');
    
      
      })
      .catch((error) => {
        console.error('Error uploading : ', error);
      });

  }


  const handleUpload = (e) => {

    e.preventDefault()
   // const uploadTask = storage.ref(`images/${image.name}`).put(image);
   // const uploadTask =  ref(storage, `images/${image.name}`).put(image)

 
    const storageRef = ref(storage, `images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
   // const storageRef = ref(storage, `files/${file.name}`);
    //const uploadTask = uploadBytesResumable(storageRef, file);
   // const uploadRef = ref(storage, 'mountains.jpg');
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress function
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        // Error function
        console.log(error);
        alert(error.message);
      },
      () => {
        // Complete function
        //  storage
        //   .ref('images')
        //   storageRef.child(image.name)
       //ref(storage, 'images')
        //   .child(image.name)
         // .getDownloadURL()
        

         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //  setImgUrl(downloadURL)
            //setUrl(url);
            alert('Image/Video uploaded successfully!');
            console.log(downloadURL)
            setUrl(downloadURL);

         
            addDoc(collection(db, "Gallery"), {
                title: title,
                desc: desc,
                src: downloadURL,
                width: 800,
                height: 600
              }) .then(() => {
                alert('Appointment booked successfully!');
            
              
              })
              .catch((error) => {
                console.error('Error adding appointment: ', error);
              });
        





            });
        

         


      }
    );
  };




  
  return (
    
<Container className='py-4'>

    
<Form  >
      <Form.Group controlId="formName">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </Form.Group>
   
    <FloatingLabel controlId="floatingTextarea2" label="Comments">
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </FloatingLabel>

      <Form.Group controlId="formName" className='mt-3'>
      
      <progress value={progress} max="100" />
        <Form.Label>Image</Form.Label>
        <Form.Control
          type="file"
          onChange={handleChange}
        //   onChange={(e) => setTitle(e.target.value)}
        
        />
      </Form.Group>
      


      <Button variant="danger" type="submit" className="mt-5 btn " onClick={handleUpload} >
      Upload
      </Button>

      {url && <Container className='img-fluid mx-auto mr-auto mt-5'><img src={url} alt="Uploaded" style={{ width: '300px' }} /></Container>}

     </Form> 

    


    </Container>







        /* <label>Title
     <input type="text"  value={title}  className="form-control mb-3" onChange={handleChange} />
     </label>
     <textarea className='form-control' value={desc}></textarea>
      <progress value={progress} max="100" />
      <br />
      <input type="file" onChange={handleChange} />
      <button onClick={handleUpload}>Upload</button>
      <br />
      {url && <img src={url} alt="Uploaded" style={{ width: '300px' }} />} */
  
  );
};

export default ImageUpload;