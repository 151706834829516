// src/components/AddStylist.js
import React, { useState } from 'react';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { db, storage } from '../../Firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { Container, Form } from 'react-bootstrap';

const AddStylist = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [services, setServices] = useState('');
  const [bio, setBio] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');



  const handleChange = (e) => {
   
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);



    }

    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      let imageUrl = '';
      if (profileImage) {
        const imageRef = ref(storage, `stylists/${profileImage.name}_${Date.now()}`);
        await uploadBytes(imageRef, profileImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      const stylistData = {
        name,
        email,
        services: services.split(',').map(service => service.trim()),
        bio,
        profileImage: imageUrl,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'stylists'), stylistData);
      setSuccess('Stylist added successfully!');
      setName('');
      setEmail('');
      setServices('');
      setBio('');
      setProfileImage(null);
    } catch (err) {
      console.error(err);
      setError('Failed to add stylist. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h2>Add New Stylist</h2>
      <Form onSubmit={handleSubmit}>

      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Form.Group>

        {/* <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div> */}


<Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your name"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>



        {/* <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div> */}

<Form.Group controlId="formName">
        <Form.Label>Services (comma separated)</Form.Label>
        <Form.Control
          type="text"
           placeholder="e.g., Haircut, Coloring, Styling"
          
          value={services}
          onChange={(e) => setServices(e.target.value)}
          required
        />
      </Form.Group>
      
    

        <FloatingLabel controlId="floatingTextarea2" label="Comments" className='mt-3'>
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </FloatingLabel>


        <FloatingLabel controlId="floatingTextarea2" label="Upload Stylist Image" className='mt-3'>
  <Form.Control 
  
  type="file"
  accept="image/*"
  onChange={handleChange}
  
  
  />
</FloatingLabel>



        <button type="submit" className='btn btn-danger mt-2' disabled={loading}>
          {loading ? 'Adding...' : 'Add Stylist'}
        </button>
      </Form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </Container>
  );
};

export default AddStylist;
