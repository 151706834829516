import Carousel from 'react-bootstrap/Carousel';

//  import slide3 from '../../assets/img/slides/Slide1.png'
import './slider.css'

import  slide3 from '../../assets/img/slides/slide3.png'
import  slide4 from '../../assets/img/slides/Slide2.png'
import { Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React from 'react'
function Slider(props) {

 
  return (

<>
<div class="row-fluid" >
    <Carousel data-bs-theme="dark">
    <Carousel.Item>
      <img
        className="d-block w-100 img-fluid"
        src={slide3}
        alt="First slide"
      />
      <Carousel.Caption>
        <h5 className="h5 text-white">WEEKLY SALES - 20% OFF </h5>
        <p className="our-desc text-white">20% of all braiding, styles, weaves and other selected sevice</p>
        <Button variant='danger' className='btn-tops'> <Link to="appointment">Book Appointment</Link></Button>
      </Carousel.Caption>
     
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={slide4}
        alt="Second slide"
      />
      <Carousel.Caption>
        <h5 className='text-white'>Braiding - Our Speciality</h5>
        <p className='text-white '>We Specialize in all kinds of braiding services</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={slide3}
        alt="Third slide"
      />


      <Carousel.Caption>
        <h5 className='text-white'>Hair Services</h5>
        <p className="text-white">
          Specialize in all kinds of hair services 
        </p>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  </div>
  </>
  );
}

export default Slider;