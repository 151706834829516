import React from 'react'

import { Container, Row, Col, Nav } from 'react-bootstrap';
import {  ListGroup } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Lists from '../../screen/Lists/Lists';
import Gallery from '../../screen/Gallery/Gallery';
const dashboardLayout = ({children}) => {
  return (
    <Container fluid>
    <Row>
      <Col sm={3} md={2} className="bg-light sidebar">
        {/* Sidebar content */}
       <>
    

 
    <ListGroup  className='mt-2 mb-3 bg-list'>
      <ListGroup.Item>
      <Link to="/upload">Add New Images</Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Link to="/admin">Appointments</Link>
      </ListGroup.Item>
      <ListGroup.Item>
      <Link to="/contacts">Messages</Link>
      </ListGroup.Item>

    </ListGroup>

       </>
      </Col>
      <Col sm={9} md={10} className="content">
        {/* Main content */}
       
        <Lists/>
        {children}
      </Col>
    </Row>
  </Container>
  )
}

export default dashboardLayout
