import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import myimg from './assets/img/Rita.jpg'
import {Row, Col} from 'react-bootstrap'
import React, {useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Slider from './components/Slider/Slider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './Firebase';
import { Link } from 'react-router-dom';
import 'swiper/css/pagination';
import 'swiper/css';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';
import axios from 'axios'
import { useState, useRef } from 'react';
import { Thumbs } from 'swiper/modules';
import 'swiper/css/navigation';
import bg from './assets/appImage.jpg'
import { services } from './Model/services';


// Import Swiper styles
import 'swiper/css';

import SpinnerComponents from './components/Spinner/SpinnerComponents'
import NewsSlide from './components/NewsSlide/NewsSlide';
import { useSelector } from 'react-redux';


function App() {
  const myStyle = {
    backgroundImage:
        `url(${bg})`,
    height: "70vh",
    marginTop: "-100px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  

  // const [Services, setServices] = useState([])

  const [isloading, setLoading] = useState(false)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);

  const [collections, setCollections] = useState([]);
  // setServices(services)

  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const fetchCollections = async () => {
      const collectionsRef = collection(db, 'Gallery');
      const snapshot = await getDocs(collectionsRef);

      const collectionList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setCollections(collectionList);

      console.log(collections)
    };

    fetchCollections();












  }, []);


const user = useSelector(state => state.firebase)

console.log(user)
  return (
    <>
    <Slider/>
    <Container fluid>

</Container>
    
    
    <div className="App">



      <Container className="mt-1 mb-2 py-5 home-services">
        <div className='mb-2 services-heading'>
        <h3 className='h3 mx-auto text-center'>OUR SERVICES </h3>
        <p className=' mx-auto text-center'>Top Services - </p>
        </div>

      <Swiper

style={{marginTop: 24}}
      spaceBetween={10}
      slidesPerView={4}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:2
        },
        1000:{
          slidesPerView:4
        },
        1500:{
          slidesPerView:4
        },
        1700:{
          slidesPerView:4
        }
    }}
      
      
      
      
      
      >
    

    {services.map(item => (
   
      <SwiperSlide key={item.id}>
  

       <Card style={{ width: '18rem' }}  className='border-0'>
     
      <div className="services-svg">{item.image}

  
      
      </div>
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Text>
        <p>{item.desc}</p>
        </Card.Text>
        {/* <Button variant="danger" className='services-btn'>Learn More</Button> */}
      </Card.Body>
    </Card>



        </SwiperSlide>

    ))}
  
     
    </Swiper>
<div className='mb-4'>


<Button className='btn-rita mb-5  ' variant=""><Link to="/services" >More Services</Link></Button>
</div>
      </Container>


   


   

<Container className="appoinment mt-4" style={myStyle} fluid>
  <div className='our-container'>

  <div className="appointment-desc mt-5 py-5">
<h3  className='text-center h4 text-danger our-heading'>WEEKLY SALES - 10% OFF </h3>
  <p className='text-center text-white app-p our-desc'>10% of all braiding, styles, weaves and other selected sevices </p>
<Button variant='danger' className='btn-rita'> <Link to="appointment">Book Appointment</Link></Button>
</div>
  </div>


  

</Container>






<Container className="bg-white mt-3 mb-5">
     

     <Row>
       <Col sm={12} lg={5}>
         
         <img src={myimg} className='about-imgs mt-4' />
       
       
       
       </Col>
       <Col sm={12} lg={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5 our-heading'>WELCOME TO RITA'S HAIR BRAIDING </h2>

<p className='py-1 our-text mt-5 our-desc'>Rita's hair Braiding Salon, where beauty, culture, and community come together to create a space of empowerment and self-expression. As a proud Black-owned beauty salon, we are dedicated to celebrating diversity and embracing the unique beauty of every client who walks through our doors.

Founded with the belief that beauty is more than skin deep, Rita's hair Braiding Salon strives to create a space where everyone feels seen, heard, and valued. Our team of talented stylists and beauty professionals specialize in a wide range of services, from natural hair care and protective styling to cutting-edge color techniques, extensions, braids, and more.</p>
<Button variant="danger" className='btn-rita app-btn float-right'><Link to='/about'>Learn More</Link></Button>

</div>


       </Col>
     </Row>
     </Container>














<Container className="mt-5  mb-4 bg-reviews" fluid>

<div className='mt-5 mb-5 services-heading'>
        <h2 className='h2 mx-auto text-center our-heading'>REVIEWS</h2>
        <p className=' mx-auto text-center our-desc'>Google reviews -What Our Customers Are Saying !!!  </p>
        </div>

  <NewsSlide/>
</Container>


      <Container className="mt-5 mx-auto mr-auto py-5 " fluid>
        <div className='mt-5 mb-5 services-heading'>
        <h2 className='h2 mx-auto text-center our-heading'>OUR MOST RECENT SERVICES</h2>
        <p className=' mx-auto text-center our-desc' >Client Looks  - Perfection at its Core </p>
        </div>

      <Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={5}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      breakpoints={{

     
          0: {
            slidesPerView: 1,
          },
          400:{
            slidesPerView:2,
          },
          639: {
            slidesPerView: 3,
          },
          865:{
            slidesPerView:3
          },
          1000:{
            slidesPerView:4
          },
          1500:{
            slidesPerView:5
          },
          1700:{
            slidesPerView:5
          }
      }}
 
      
      
      
      
      
      >
    

    {collections.map(item => (
   
      <SwiperSlide key={item.id}>
  

       <Card style={{ width: '18rem' }}  className='border-0'>
     
      {/* <div className="services-svg">{item.image}

  
      
      </div> */}
      <img src ={item.src} className='work-images' />
      <Card.Body>
        <Card.Title>{item.title}</Card.Title>
        <Card.Text>
        <p>{item.desc}</p>
        </Card.Text>
        {/* <Button variant="danger" className='services-btn'>Learn More</Button> */}
      </Card.Body>
    </Card>



        </SwiperSlide>

    ))}
  
     
    </Swiper>


      </Container>











    
    </div>

    </>
  );
}

export default App;
