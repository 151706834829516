import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
export default function NotFound() {
    return (
        <Container className="appoinment mb-5 mt-4 "  fluid>
            <Row>
                <Col className="text-center">
                <h1 className="text-center">Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/' color="danger"><p className="text-danger">Home</p></Link>
            <Link to='/appointment' color="danger"><p className="text-danger">Book An Appointment</p></Link>
                </Col>
          

            </Row>
           
            </Container>
       
    )
}