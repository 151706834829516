import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
const SpinnerComponents = () => {
  return (
    <div className='mx-auto text-center mb-5 mt-5'>Spinner

<Spinner
          as="span"
          animation="border"
          variant="danger"
          size="lg"
          role="status"
          mx-auto
          className='text-center'
          aria-hidden="true"
        />

.....Loading Post
    </div>
  )
}

export default SpinnerComponents