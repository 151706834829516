
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk'
import {faLocation} from '@fortawesome/free-solid-svg-icons/faLocation'
import {ReactComponent as Dryer} from '../assets/svg/Hairstyle.svg'
import {ReactComponent as Brush} from '../assets/svg/hair-washing-sink-svgrepo-com.svg'

import {ReactComponent as Scrub} from '../assets/svg/scrub-disc-svgrepo-com.svg'

import {ReactComponent as Comb} from '../assets/svg/comb-svgrepo-com.svg'
export const services = [
    {
      id: 0,
      title: 'Braiding',
      image: <Dryer/>,
      desc: "Braiding is our services "
    },
    {
      id: 1,
      title: 'Styling',
      image: <Brush/> ,
      desc: "All kinds of Corn Rows"
    },
  
    {
      id: 2,
      title: 'Corn Rows',
      image: <Comb/>,
      desc: "All kinds of Styling Services "
    },
  
  
    {
      id: 3,
      title: 'Styling',
      image: <Scrub/>,
      desc: "All kinds of Styling Services "
    }
  ]