// src/components/AppointmentList.js
import React, { useState, useEffect } from "react";
import { db } from "../../Firebase";
import { collection, query, where, getDocs, getDoc, updateDoc, doc, or } from "firebase/firestore";
import emailjs from '@emailjs/browser';
import { useSelector } from "react-redux";

import { Row, Col, Container} from "react-bootstrap";



const AppointmentList = ({ customerEmail }) => {

    const users = useSelector(state => state.user)

    const{user, isLoggedIn} = users

    const[appointmentList, setAppointmentList] = useState([])

    const [userId, setUserId] = useState(null)
     
  const [isAdmin, setIsAdmin] = useState(false);

  const [Loading, setLoading] = useState(false)

  const [appointments, setAppointments] = useState([]);

    const fetchUserData = async () => {
        if(user !== null){
    
            const userRef = doc(db, 'Users', user.uid);
    
            setUserId(user.uid)
    
            const userSnap = await getDoc(userRef);

            console.log('snap',userSnap)
            if (userSnap.exists()) {
              setIsAdmin(userSnap.data().isAdmin);  // Check if user is an admin

              console.log("isad",isAdmin)
            }
    
            console.log("test",userSnap)
      
        }
      
       
      };



        // Fetch deliveries based on whether user is an admin
  const fetchAppointments = async () => {
    let q;

    if (isAdmin) {
      // Admin: Get all deliveries
      q = query(collection(db, 'appointments'));

      console.log("q",q)
    } else {
      // Non-admin: Get only the user's deliveries
      q = query(collection(db, 'appointments'), where('userId', '==', userId));
    }

    const querySnapshot = await getDocs(q);
    const deliveriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
   // setAppointmentList(deliveriesData);
    setAppointments(deliveriesData)
  
    setLoading(false);
  };



    console.log("users",users)


  useEffect(() => {


    const getData = async () => {
        fetchUserData()
    fetchAppointments() // Then fetch deliveries based on role
      };
      getData();


    // fetchUserData()
    // fetchAppointments()
    // const fetchAppointment = async () => {
    //   const appointmentsRef = collection(db, "appointments");
    //   const q = query(appointmentsRef, where("email", "==", customerEmail ));
    //   const querySnapshot = await getDocs(q);
    //   const appointmentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //   setAppointments(appointmentsData);
    // };

    // fetchAppointment();
  }, [userId, isAdmin]);

  const handleCancel = async (appointmentId) => {
    const appointmentRef = doc(db, "appointments", appointmentId);
    await updateDoc(appointmentRef, { status: "canceled" });

    // Send cancellation email
    sendCancellationEmail(appointmentId);

    setAppointments(prev => prev.filter(app => app.id !== appointmentId));
    alert("Appointment canceled.");
  };

  const sendCancellationEmail = (appointmentId) => {
    // Fetch appointment details
    const appointment = appointments.find(app => app.id === appointmentId);
    if (!appointment) return;

    const params = {
      appointmentId,
      customerName: appointment.customerName,
      appointmentDate: appointment.time.toDate().toString(),
      stylistId: appointment.stylistId,
      recipient: appointment.customerEmail,
    };

    // Send cancellation email to customer
    emailjs.send(
      "YOUR_EMAILJS_SERVICE_ID",
      "YOUR_EMAILJS_CANCELLATION_TEMPLATE_ID",
      params,
      "YOUR_EMAILJS_USER_ID"
    ).then(
      (result) => {
        console.log("Cancellation email sent:", result.text);
      },
      (error) => {
        console.error("Error sending cancellation email:", error.text);
      }
    );
  };

  return (
    <div>
      <h2 className="text-center text-danger mb-3 mt-3">YOUR APPOINTMENT</h2>
    



      <Container>
        <Row>
    
        {appointments.map((item) => (

            <Col lg={3} md={3} sm={12}>

            

            {/* <div class="list-group mb-3">
                <div className="list-group-item  flex-column align-items-start">


                <p>{item.name}</p>
            <p>{item.time}</p>
            <p>{item.services}</p>
                </div>

          

            </div> */}





            <div class="card mt-3">
            {item.styleImageUrl? <img class="card-img-top" src={item.styleImageUrl} alt="Card image cap"/> : null}
  <div class="card-header">
   Name: {item.name}
  </div>
  <div class="card-body">
    <h6 class="card-title">Time: {item.time} || Status: {item.status}</h6>
    <p class="card-text">Services: {item.services}</p>
    <div>Stylist: {item.stylistId}</div>
    <button  className="btn btn-danger" onClick={() => handleCancel(item.id)} disabled={item.status === "canceled"}>
              Cancel Appointment
            </button>
  </div>
</div>












           
            </Col>
        ))}
        </Row>
      </Container>
    </div>
  );
};

export default AppointmentList;
