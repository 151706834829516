// src/components/StylistsSelector.js
import React, { useState, useEffect } from "react";
// import { db } from "../firebase";

import { db } from "../../Firebase";
import { collection, getDocs } from "firebase/firestore";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Button } from 'react-bootstrap';
const StylistSelector = ({ selectedStylist, setSelectedStylist }) => {
  const [stylists, setStylists] = useState([]);

  useEffect(() => {
    const fetchStylists = async () => {
      const stylistsCollection = collection(db, "stylists");
      const stylistSnapshot = await getDocs(stylistsCollection);
      const stylistList = stylistSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStylists(stylistList);
    };

    fetchStylists();
  }, []);

  return (
    <div>
      {/* <label>Select Stylist:</label> */}
      <FloatingLabel>

     
      <Form.Select
        value={selectedStylist}
        onChange={(e) => setSelectedStylist(e.target.value)}
        required
      >
        <option value="">--Select Stylist--</option>
        {stylists.map((stylist) => (
          <option key={stylist.id} value={stylist.id}>
            {stylist.name}
          </option>
        ))}
      </Form.Select>
      </FloatingLabel>
    </div>
  );
};

export default StylistSelector;
