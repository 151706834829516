import React,{useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {LinkContainer} from 'react-router-bootstrap'
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import about from '../../assets/img/slides/Abouts.png'
import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee'
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone'
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk'
import {faLocation} from '@fortawesome/free-solid-svg-icons/faLocation'
import { getAuth, signOut } from "firebase/auth";

import { db } from '../../Firebase';

import { SocialIcon } from 'react-social-icons'
import  'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'

import Modal from '../Modal/Modal';

import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { Col,Row, ListGroup,} from 'react-bootstrap'
;
import NavDropdown from 'react-bootstrap/NavDropdown';
import { MemoryRouter, Routes, Route, NavLink, Navigate, useNavigate } from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';

 import channel from "../../assets/RitasLogo.png"
 import {  where, getDoc, updateDoc, doc, or } from "firebase/firestore";
import './Header.css'
import ReactDOM from "react-dom";
import Signin from '../SignModal/Signin';
import Register from '../Register/Register';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_SUCCESS, signOuts } from '../../Redux/actions/firebaseActions';
import Admin from '../dashboardLayout/dashboardLayout';
import { logout } from '../../Redux/Slices/userSlice';




const Header = () => {

  const auth = getAuth();

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const signOutHandle = async () => {


    // await signOut(auth).then(() => {
    //   // Sign-out successful.
     

     

    // }).catch((error) => {
    //   // An error happened.
    // })

    // auth.signOut()
    dispatch(logout())
    navigate('/')

  }
  

  const [isAdmin, setIsAdmin] = useState(false)
  
  // const user = useSelector(state => state.user)
  const users = useSelector(state => state.user)

  const{user, isLoggedIn} = users

  const [userId, setUserId] = useState(null)



  const fetchUserData = async () => {
    if(user !== null){

        const userRef = doc(db, 'Users', user.uid);

        setUserId(user.uid)

        const userSnap = await getDoc(userRef);

        console.log('snap',userSnap)
        if (userSnap.exists()) {
          setIsAdmin(userSnap.data().isAdmin);  // Check if user is an admin

          console.log("isad",isAdmin)
        }

        console.log("test",userSnap)
  
    }
  
   
  };


useEffect(() => {

  fetchUserData()

},[userId, user])



  console.log("isAdmi User", user)

  // const {isLoggedIn, error} = user

  const [open, setOpen] = React.useState(false);
  return (
    <>
    
    
    <div  className='my-top'>

    <Row>
        <Col sm={3} className='mt-3 mx-auto  top-address'>
        
        <div className='top-icons'>

        <FontAwesomeIcon icon={faLocation}  className='text-primary mr-5'/>
       
        <ListGroup>
       
      <ListGroup.Item className="text-center">14800 Buildamerica Dr, </ListGroup.Item>
      <ListGroup.Item className="text-center">Woodbridge, VA 22191</ListGroup.Item>
      
    </ListGroup>
          
    </div>
          </Col>


        <Col sm={3} className='mt-3 mx-auto top-phone'>
          <div className='top-icons  top-phone'>

          <FontAwesomeIcon icon={faPhone}  className='text-primary mr-5'/>
          <ListGroup>
      <ListGroup.Item className="text-center"> <a href="tel:703-491-5808">703-731-4877</a></ListGroup.Item>
      <ListGroup.Item className="text-center">Book An Appointment</ListGroup.Item>
    </ListGroup>
          </div>
          </Col>
        <Col sm={3} className='mt-3 mx-auto top-clock'>
        <div className='top-icons'>
        <FontAwesomeIcon icon={faClock}  className='text-primary mr-5'/>
          <ListGroup>
      <ListGroup.Item className="text-center"><h6 className='h6 hours-clock'>Mon-Sat:Open All Day</h6></ListGroup.Item>
      <ListGroup.Item className="text-center"><h6 className='h6 hours-clock'> Sundays 10:00am - 7:00pm</h6></ListGroup.Item>

    </ListGroup>
          
          </div>
          </Col>



          <Col sm={3} className='mt-3 mx-auto mr-auto'>
          
          <div className='top-social'>
<SocialIcon url="https://www.facebook.com/p/Ritas-Clothing-Beauty-Salon-100063577181021/" />
    <SocialIcon url="https://www.instagram.com/ri_ta7918?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" />
    <SocialIcon url="http://www.youtube.com/@RitaHairBraidingSalon-n6m" />
    <SocialIcon url="https://www.tiktok.com/@user5253286120073"/>

</div>
          
          </Col>
      </Row>
    </div>
    
   

    <Navbar expand="lg" className="bg-body-teritary">
       
    <Container >
      <Navbar.Brand to="/"><img src={channel}  style={{width: '80px', height: '80px' }} className="ml-5"/></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
          navbarScroll
        >
          {/* <Nav.Link to="/">Home</Nav.Link> */}
          <LinkContainer to="/">
          <Nav.Link>Home</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/services">
          <Nav.Link>Services</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/appointment">
          <Nav.Link>Appointment</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/gallery">
          <Nav.Link>Gallery</Nav.Link>
          </LinkContainer>
          <NavDropdown title="About" id="navbarScrollingDropdown" >
          <LinkContainer to="/about">
            <NavDropdown.Item>ABOUT US</NavDropdown.Item>
            </LinkContainer>

<LinkContainer to="/contact" className="text-success">
            <NavDropdown.Item >
              CONTACT US
            </NavDropdown.Item>
            </LinkContainer>


<LinkContainer to="/reviews">
            <NavDropdown.Item>
              Privacy Terms
            </NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Divider />
           
          </NavDropdown>


          { isLoggedIn && isAdmin ?

          <NavDropdown title="Admin" id="navbarScrollingDropdown" >
          <LinkContainer to="/upload">
            <NavDropdown.Item>Add Gallery Images</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/addstylist">
            <NavDropdown.Item>Add Stylist</NavDropdown.Item>
            </LinkContainer>

<LinkContainer to="/lists" className="text-success">
            <NavDropdown.Item >
             View All Appointment
            </NavDropdown.Item>
            </LinkContainer>



            <LinkContainer to="/appointments" className="text-success">
            <NavDropdown.Item >
             View All Appointments
            </NavDropdown.Item>
            </LinkContainer>
          
           
          </NavDropdown>

        : null}
        
          <LinkContainer to="/location">
          <Nav.Link  className="">
           Location
          </Nav.Link>
          </LinkContainer>



         {/* <Nav.Link> */}
         <div className='mt-2 ml-1'>

         <a  href="https://beautypalaceandsupplies.com" target="_blank"
      rel="noopener noreferrer">SHOP BEAUTY SUPPLIES</a>
         </div>
       
         {/* </Nav.Link> */}
         
     

        </Nav>
        {/* <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
          />
          <Button variant="outline-primary">Search</Button>
        </Form> */}

      

       
       <Nav className='top-lock'>

{!isLoggedIn && 
<>
<div className='mt-2'>
<Modal />
</div>
       
          <Nav.Link  className="">
           <Signin/>
          </Nav.Link>
          </>

}

          {isLoggedIn  && isAdmin ?
          <LinkContainer to="/admin" className='mt-1'>
          <Nav.Link>Admin</Nav.Link>
          </LinkContainer>
: null}
          <Nav.Link  className="mt-3">
           {/* <Register/> */}
           {/* <Admin/> */}
          </Nav.Link>
          {isLoggedIn && 
          <Nav.Link  className=" mt-3">
 
           <div onClick={signOutHandle}> LogOut </div>
          </Nav.Link>

         }
        </Nav>
      </Navbar.Collapse>
    </Container>


  </Navbar>


  
  </>
  )
}

export default Header