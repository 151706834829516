import React, { useEffect, useState } from 'react';
// import app from '../../Firebase';
import Container  from 'react-bootstrap/Container';
import {getFirestore } from 'firebase/firestore'
import { Form, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {db,storage} from '../../Firebase'
import './style.css'
import emailjs from '@emailjs/browser';
import {  serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { collection, addDoc } from "firebase/firestore";
import YourComponentWithCaptcha from '../YourComponentWithCaptcha/YourComponentWithCaptcha';
import StylistSelector from '../StylistsSelector/StylistsSelector';
import { useSelector } from 'react-redux';
import { redirect, useNavigate } from 'react-router-dom';

const AppointmentForm = () => {
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const navigate = useNavigate()
  const users = useSelector(state => state.user)

  const [isloading, setLoading] = useState(false)

  const{user, isLoggedIn} = users

  const options = [
    { label: 'Pick A Service', value: '' },
    { label: 'Braiding', value: 'braiding' },
    { label: 'Styling', value: 'styling' },
    { label: 'Other', value: 'other' },
    {label: 'Corn Row', value: 'Corn Row'},
    {label: 'Weaving', value: 'Weaving'}

  ];
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [tel, setTel] = useState(null);
  const [email, setEmail] = useState('')
  const [desc, setDesc] = useState('')
  const [stylistId, setStylistId] = useState("");
  const [styleImage, setStyleImage] = useState(null);

  const [selectedServices, setSelectedServices] = useState('');




  useEffect(() => {

    if(!isLoggedIn){

    

      navigate('/login')

    }


  }, [navigate])

  // Function to handle dropdown change
  //const handleServiceChange = (e) => {
    //setServices(e.target.value);
  //   setSelectedServices(e.target.value)
  //   console.log(selectedServices)
  // };

  console.log(selectedServices)
  // const db = getFirestore(app)

  console.log(db)

  const handleSubmit = async(e) => {
    e.preventDefault();

       // Upload the image if provided
       let imageUrl = "";
       if (styleImage) {
         const storageRef = ref(storage, `styles/${styleImage.name}_${Date.now()}`);
         await uploadBytes(storageRef, styleImage);
         imageUrl = await getDownloadURL(storageRef);
       }
  
    addDoc(collection(db, "appointments"), {
      name: name,
      date: date,
      time: time,
      tel:tel,
      email:email,
      desc:desc,
      services:selectedServices,
      stylistId,
      styleImageUrl: imageUrl,
      status: "scheduled",
      createdAt: serverTimestamp(),
    }) .then(() => {
      alert('Image/Video Uploaded successfully!');
      var templateParams = {
        name: 'James',
        notes: 'Check this out!',
        recipient: email,
        message: `Appointment book for ${date}, time is ${time}, service :${selectedServices} , Details: ${desc}, Name: ${name},  and Phone number is:  ${tel}`
      };
      const publickey = 'RG6qCqg7jgAFudkTK'

      emailjs.send('service_euhqfak', 'template_n7uzhpo', templateParams,publickey).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          console.log(response.text);
          alert('Email sent successfully!');
        },
        (error) => {
          console.log(error.text);
          alert('Failed to send email. Please try again later.');
        });
  
      

        var templateParams2 = {
          name: 'James',
          notes: 'Check this out!',
          recipient: 'nastaanaisie@gmail.com',
          message: `Appointment book for ${date}, time is ${time}, service :${selectedServices} , Details: ${desc}, Name: ${name},  and Phone number is:  ${tel}`
        };
        //const publickey = 'RG6qCqg7jgAFudkTK'
  
        emailjs.send('service_euhqfak', 'template_n7uzhpo', templateParams2,publickey).then(
          (response) => {
            console.log('SUCCESS!', response.status, response.text);
            console.log(response.text);
            alert('Appointment Booked Successfully');
          },
          (error) => {
            console.log(error.text);
            alert('Failed to Book An Appointment. Please try again later.');
          });
    


      // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', email, 'YOUR_USER_ID')
      // .then((result) => {
      //   console.log(result.text);
      //   alert('Email sent successfully!');
      //   // Optionally clear the form inputs
      //  // setToEmail('');
      //   //setMessage('');
      // }, (error) => {
      //   console.log(error.text);
      //   alert('Failed to send email. Please try again later.');
      // });







      // Optionally, clear the form fields
      setName('');
      setDate('');
      setTime('');
      setEmail('');
      setDesc('');
      setSelectedServices('');
    })
    .catch((error) => {
      console.error('Error adding appointment: ', error);
    });
  };
  // const handleCaptchaChange = (token) => {
  //   // Handle CAPTCHA verification here
  //   setCaptchaVerified(true);
  // };
  return (

    <Container className='py-4'>

    
<Form onSubmit={handleSubmit} className='app-form'>
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="formName" className="mt-3">
        <Form.Label>E-mail Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formName" className="mt-3">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Enter Phone Number"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formDate" className='mt-3'>
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formTime" className='mt-3'>
        <Form.Label>Time</Form.Label>
        <Form.Control
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          required
        />
      </Form.Group>


      <Form.Group className="mt-3">

  
      <Form.Select value={selectedServices} onChange={e => setSelectedServices(e.target.value)}  defaultValue="orange">
    
      {/* <option value="">Pick A Service</option> */}

      {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
      
    </Form.Select>
    </Form.Group>

    <FloatingLabel controlId="floatingTextarea2" label="Comments" className='mt-3'>
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </FloatingLabel>

      {/* <FloatingLabel controlId="floatingTextarea2" label="Comments" className='mt-3'>  */}
   
        {/* <StylistSelector selectedStylist={stylistId} setSelectedStylist={setStylistId} /> */}

        <Form.Group className='mt-3'>

        <StylistSelector selectedStylist={stylistId} setSelectedStylist={setStylistId}/>

        </Form.Group>

        
{/*      
      </FloatingLabel> */}

<FloatingLabel controlId="floatingTextarea2" label="Upload Style Image" className='mt-3'>
  <Form.Control 
  
  type="file"
  accept="image/*"
  onChange={(e) => setStyleImage(e.target.files[0])}
  
  
  />
</FloatingLabel>
   
      <Button variant="danger" type="submit" className="mt-5 btn ">
        Submit
      </Button>
    </Form>
    </Container>
  );
};

export default AppointmentForm;