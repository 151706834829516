import Carousel from 'react-bootstrap/Carousel';

import './slider.css'
import { Button, Container } from 'react-bootstrap';

import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';

import { ListGroup } from 'react-bootstrap';




import {faStar} from '@fortawesome/free-solid-svg-icons/faStar'
import {faStarHalfAlt} from '@fortawesome/free-solid-svg-icons/faStarHalfAlt'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function NewsSlide() {
 
    const [data, setData] = useState([])
    const [reviews, setReviews] = useState([]);

    const [rating , setRating] = useState('')
    useEffect(() => {

    

      const apiKey = "AIzaSyCNHEa4WLAJvgzoZptLJETQePYSmM8FhTY"
      
      const placeId = 'ChIJn3qmtPFVtokRS_kWyjfuYCE'; // Replace with your actual Google Place ID
      const myurl = 'https://thesalon-4156e.web.app/'
      const apiUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`;

      const fin = `/details/json?place_id=${placeId}&key=${apiKey}`
      var config = {
        method: 'get',
        url: fin, //the rest of your url
        secure: false //important
      };
      
  

      console.log("url", fin)
     
  
      axios(config)
  .then(function (response) {
    //console.log(JSON.stringify(response.data));
    console.log( response.data.result.rating)
    console.log("Reviews", response.data.result.reviews)
setRating(response.data.result.rating);
    const myinfo = response.data.result.reviews

    setReviews(response.data.result.reviews)

    console.log('reviews',reviews)
  })
  .catch(function (error) {
    console.log(error);
  });
  





  const fetchReviews = async () => {
    // const apiKey = 'YOUR_GOOGLE_PLACES_API_KEY';
    // const placeId = 'YOUR_GOOGLE_PLACE_ID'; // This is the unique identifier for your place

    const apiKey = process.env.APIKEY
    const placeId ='ChIJn3qmtPFVtokRS_kWyjfuYCE';

    try {
      const response = await axios.get(`https://us-central1-ritashairbraidingsalon.cloudfunctions.net/rita/fetchGoogleData?place_id=${placeId}`);
      console.log("our reviews from api", response)
      setReviews(response.data.result.reviews);
      setRating(response.data.result.rating);

      console.log(rating)
    } catch (error) {
      console.error('Error fetching Google reviews:', error);
    }
  };



  fetchReviews();



    
      }, [])



      
 
  return (



    

<Container className='mt-3 mb-4'>
  Current Rating : {rating}
  {rating > 3.7 ?
  <div className='rating'>
  <FontAwesomeIcon  icon={faStar}  className='rating-icons'/>
  <FontAwesomeIcon  icon={faStar} className='rating-icons' />
  <FontAwesomeIcon  icon={faStar}  className='rating-icons'/>
  <FontAwesomeIcon  icon={faStarHalfAlt}  className='rating-icons'/>
  </div>
: 5}
<div class="row-fluid">
    <Carousel data-bs-theme="dark" className="mb-4">

        {reviews && reviews.map((item, index) => (
    <Carousel.Item key={item.index} >
      <img
        className="d-block mx-auto imgstyle"
        src={item.profile_photo_url}
      
        alt="First slide"
      />
     
     <Carousel.Caption className="mt-5">

</Carousel.Caption>

<div className="mb-5 mt-3">

<h5 className=''>{item.author_name}</h5>
<p className='text-truncate '>{item.text}</p>

<span>Review Date: {item.relative_time_description} |  rating: {item.rating}</span>

</div>
    </Carousel.Item>


))}



  </Carousel>
  <Button className="btn-rita" variant='danger'> <Link to="https://www.google.com/search?client=safari&rls=en&q=Rita%27s+Hair+Braiding+and+Beauty+Supplies&ie=UTF-8&oe=UTF-8&dlnr=1&sei=txL6Zp__J7iy5NoPlY_k0Ag#">Add New Review </Link>  </Button>
  </div>
  
  </Container>
  );
}

export default NewsSlide;