import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons/faSignOut';
import { getAuth, createUserWithEmailAndPassword , signInWithEmailAndPassword } from "firebase/auth";

import  './signin.css'
import { useDispatch } from 'react-redux';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../../Redux/actions/firebaseActions';
import { login, signIn } from '../../Redux/Slices/userSlice';

function Signin() {
  const [show, setShow] = useState(false);
  const auth = getAuth();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


const dispatch = useDispatch()

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      // 1. Create user with email and password
    //   const userCredential = await createUserWithEmailAndPassword(auth,email, password ) auth.createUserWithEmailAndPassword(email, password);

   // const userCredential = await createUserWithEmailAndPassword(auth,email, password )




   await signInWithEmailAndPassword (auth, email, password)
   .then((userCredential) => {
     // Signed up 
     const user = userCredential.user;

     dispatch(login(userCredential.user));
    //  const user = userCredential.user;
     alert('Login successful!');
 
 
     if(user){
 
         dispatch({ type: LOGIN_SUCCESS });
 
         handleClose()
     } else{
 
 
         
     }
 
      // user.updateProfile({
      //    displayName: displayName
      //  });
  
 
     console.log(user)
  
   })
   .catch((error) => {
     const errorCode = error.code;
     const errorMessage = error.message;
 
     dispatch({ type: LOGIN_FAILURE, payload: error.message });
     // ..
   });
       
       // 2. Set display name
      
 
       //console.log('Successfully registered:', userCredential.user);
       // You can redirect or show a success message here
     } catch (error) {
       console.error('Error registering:', error);
       // Handle errors, e.g., show error messages to the user
     }
  
  };






  return (
    <>
    
      <FontAwesomeIcon  icon={faSignOut}  onClick={handleShow} className='top-icons top-glass ml-3 mr-2 mx-auto '  />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>SignIn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className='signin' onSubmit={handleSignIn}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                value={email}
        onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                autoFocus
                value={password}
        onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
           <Button className='signin-btn' variant='danger' onClick={handleSignIn}>Sign In</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Signin;