import React from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'

import myimg from '../../assets/hairStyle.png'
import './about.css'
import about from '../../assets/Background.png'

import video from '../../assets/rita.mp4'
import SEO from '../../components/SEO'
const About = () => {
  return (
    <>
      {/* <Container className='py-2'></Container> */}
    <Container fluid>

    <SEO title="About US" description="Welcome to Ritas Clothing and Beauty Salon, where beauty, culture, and community come together to create a space of empowerment and self-expression." />

    <div className='p-5 text-center bg-image fluid'
        style={{ backgroundImage: `url(${about})`, height: 600, backgroundRepeat: "no-repeat" , backgroundPosition: 'center',
        backgroundSize: 'cover'}}>
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white py-3'>
              <h1 className='mb-3'>ABOUT US</h1>
              <p className='mb-3'>Our Story - Mission </p>
              {/* <a className='btn btn-outline-light btn-lg' href='#!' role='button'>
                Call to action
              </a> */}
            </div>
          </div>
        </div>
        </div>

        </Container>

        <Container>

<Row>
        <Col sm={4}>
          
          {/* <img src={myimg} className='about-img mt-4' /> */}
        
        {/* <iframe> src={video}</iframe> */}
<div className='spacer'></div>
        <video width="100%" height="350" controls className=' mt-5'>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        
        </Col>
        <Col sm={8}>


<div className="p text-justify py-5 mt-5">

  <h2 className='h3'> RITA'S CLOTHING AND BEAUTY SALON </h2>

<p className='py-1 text-wrap text-break about-desc'>Welcome to Rita's Clothing And Beauty Salon, where beauty, culture, and community come together to create a space of empowerment and self-expression. As a proud Black-owned beauty salon, we are dedicated to celebrating diversity and embracing the unique beauty of every client who walks through our doors.

Founded with the belief that beauty is more than skin deep, Rita's hair Braiding Salon strives to create a space where everyone feels seen, heard, and valued. Our team of talented stylists and beauty professionals specialize in a wide range of services, from natural hair care and protective styling to cutting-edge color techniques, extensions, braids, and more.

At Rita's hair Braiding Salon, we understand that hair and beauty are deeply rooted in identity and self-confidence, especially within the Black community. That’s why we prioritize healthy hair practices, using only top-quality products that nourish, protect, and enhance natural textures and styles.

Our mission is simple: to provide exceptional service, build lasting relationships, and empower our clients to look and feel their best. Whether you’re here for a bold new look, maintenance of your natural hair, or just some well-deserved pampering, you can trust that our team is committed to making you feel at home and leaving you feeling radiant.

We take pride in being more than just a salon—we are a community. As we continue to grow, we remain dedicated to uplifting and supporting our clients, staff, and the surrounding community. Rita's hair Braiding Salon, is a place where culture, creativity, and care meet.

Join us, and let us celebrate your beauty, inside and out.

Rita's hair Braiding Salon—Where Beauty Meets Empowerment</p>

</div>


        </Col>
      </Row>


    </Container>
 

   </>
  )
}

export default About
