
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import { getStorage } from 'firebase/storage';

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwKwGb0hLJ7SCYKkzPmTGJc5TeqfHTeqM",
  authDomain: "ritashairbraidingsalon.firebaseapp.com",
  projectId: "ritashairbraidingsalon",
  storageBucket: "ritashairbraidingsalon.appspot.com",
  messagingSenderId: "1046129812382",
  appId: "1:1046129812382:web:13f13840b0344359f12c8f",
  measurementId: "G-XN90K5Z9L1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LcrlQgqAAAAAEq4vaSZ6ANttAt1-QWbiNJdSRuP'),


//   isTokenAutoRefreshEnabled: true
// });



  const db  = getFirestore(app)
  const storage = getStorage(app);
  const auth = getAuth(app);
  export {db}
  export {storage}
  export {auth}
// export default app