
import AppointmentForm from '../../components/AppointmentForm/AppointmentForm'

import React from 'react'
import  Container  from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bg from '../../assets/img/bg.jpg'
import {ReactComponent as Dryer} from '../../assets/svg/Hairstyle.svg'
import {ReactComponent as Brush} from '../../assets/svg/hair-washing-sink-svgrepo-com.svg'

import {ReactComponent as Scrub} from '../../assets/svg/scrub-disc-svgrepo-com.svg'

import {ReactComponent as Comb} from '../../assets/svg/comb-svgrepo-com.svg'
import SEO from '../../components/SEO';
const Appointment = () => {
  const myStyle = {
    backgroundImage:
        `url(${bg})`,
    height: "50vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (

    <>

    <Container className="appoinment" style={myStyle} fluid>

    <SEO title="Appointment " description="To Book an appointment , use this page." />


  <div className='our-container'>

  <div className="appointment-desc mt-4">
<h4  className='text-center h4 text-white'>To Book An Appointment </h4>
  <p className='text-center text-white app-p'>Use the Form Below To Book An Appointment </p>
{/* <Button variant='danger'>Book Appointment</Button> */}
</div>
  </div>


  

</Container>

  
    <Container>

      <p className='text-wrap'>
      To book an appointment, please fill out the form below. Also to confirm your appointment, a deposit of $50 is required.  Payment can be made after filling out this form. After booking an appointment. 
Please call 703-731-4877 to confirm your appointment.
      </p>



<AppointmentForm/>


    </Container>

    </>
   
  )
}

export default Appointment