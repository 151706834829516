import firebaseReducer from "../reducers/firebaseReducer";
import { getAuth, signOut } from "firebase/auth";


import { auth } from "../../Firebase";
import { signInWithEmailAndPassword } from 'firebase/auth'

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';


//const auth = getAuth()

// Action Creators
// export const signIn = (email, password) => async (dispatch) => {
//   try {
//     await auth.signInWithEmailAndPassword(email, password);
//     dispatch({ type: LOGIN_SUCCESS });
//   } catch (error) {
//     dispatch({ type: LOGIN_FAILURE, payload: error.message });
//   }
// };

export const signOuts = () => async (dispatch) => {
  try {
    //await auth.signOut();
    await signOut(auth)
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    console.error('Error signing out:', error);
  }
};


export const signIn = createAsyncThunk(
  'user/signIn',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = {
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        displayName: userCredential.user.displayName,
        photoURL: userCredential.user.photoURL,
      };
      return user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);