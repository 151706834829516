import React from 'react'
import  Container  from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bg from '../../assets/img/bg.jpg'
import {ReactComponent as Dryer} from '../../assets/svg/Hairstyle.svg'
import {ReactComponent as Brush} from '../../assets/svg/hair-washing-sink-svgrepo-com.svg'
import './Services.css'
import {ReactComponent as Scrub} from '../../assets/svg/scrub-disc-svgrepo-com.svg'

import {ReactComponent as Comb} from '../../assets/svg/comb-svgrepo-com.svg'
const Services = () => {

  const myStyle = {
    backgroundImage:
        `url(${bg})`,
    height: "50vh",
    marginTop: "-70px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  
  return (
    <div>
      
<Container className="appoinment" style={myStyle} fluid>
  <div className='our-container'>

  <div className="appointment-desc mt-4">
<h4  className='text-center h4 text-white'>Our Services </h4>
  <p className='text-center text-white app-p'>Below are our services </p>
{/* <Button variant='danger'>Book Appointment</Button> */}
</div>
  </div>


  

</Container>


<Container className="bg-white mt-3">
     

     <Row>
       <Col sm={5} className='services-svg mt-5 py-5'>
         
    <Brush/>
       
       
       
       </Col>
       <Col sm={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5'>Hair Styling</h2>

<p className='py-1 our-text mt-5'>Unique hair styling , tailored to your looks.</p>


</div>


       </Col>
     </Row>
     </Container>


<hr className='py-2 ml-2'/>




<Container className="bg-white mt-3">
     

     <Row>
       <Col sm={5} className='services-svg mt-5 py-5'>
         
    <Dryer/>
       
       
       
       </Col>
       <Col sm={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5'>Corn Rows</h2>

<p className='py-1 our-text mt-5'>Your  unique style of corn rows.</p>


</div>


       </Col>
     </Row>
     </Container>






     <hr className='py-2 ml-2'/>




<Container className="bg-white mt-3">
     

     <Row>
       <Col sm={5} className='services-svg mt-5 py-5'>
         
    <Scrub/>
       
       
       
       </Col>
       <Col sm={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5'>Weaving</h2>

<p className='py-1 our-text mt-5'>Your  unique style of natural twist.</p>


</div>


       </Col>
     </Row>
     </Container>







<hr/>






<Container className="bg-white mt-3">
     

     <Row>
       <Col sm={5} className='services-svg mt-5 py-5'>
         
    <Scrub/>
       
       
       
       </Col>
       <Col sm={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5'>Stich Braids </h2>

<p className='py-1 our-text mt-5'>Your  unique style of natural Stich Braids.</p>


</div>


       </Col>
     </Row>
     </Container>







<hr/>





     <Container className="bg-white mt-3">
     

     <Row>
       <Col sm={5} className='services-svg mt-5 py-5'>
         
    <Comb/>
       
       
       
       </Col>
       <Col sm={7}>


<div className="p text-justify py-5 mt-3">

 <h2 className='h3 mt-5'>Braiding</h2>

<p className='py-1 our-text mt-5'>Your  unique style of natural twist.</p>


</div>


       </Col>
     </Row>
     </Container>


    </div>
  )
}

export default Services
